import { createMuiTheme } from '@material-ui/core';

export const colorDefinitions = {
  text1: { light: '#3b3e66', dark: '#d4d6d7' }, // navy text
  accent1: { light: '#0088e8', dark: '#47b3ff' },
  accent2: { light: '#f66f27', dark: '#f66f27' },
  active: { light: '#eeeeee', dark: '#5b5b5b' }, // active event
  border: { light: '#c4c4c4', dark: '#6e6e6e' },
  paper: { light: '#fff', dark: '#424242' }, // tooltip paper
  grey1: { light: '#dedfe3', dark: '#343434' }, // card background grey
  grey2: { light: '#dedfe3', dark: '#5b5b5b' }, // avatar bcackground
  grey3: { light: '#999999', dark: '#7c7c7c' }, // tooltip border color
  grey4: { light: '#f9f9f9', dark: '#393939' }, // very light grey
  grey5: { light: '#f5f5f5', dark: '#515151' }, // light grey used in menu hover
  grey6: { light: '#666', dark: '#999' }, // text lighter
  orange1: { light: '#FFA500', dark: '#FFA500' },
  formPlaceholder: { light: '#999', dark: '#999' },
  siteBackground: { light: '#e5e5e5', dark: '#212325' },
  siteTextColor: { light: '#3f3f3f', dark: '#ccc' },
  primary: { light: '#0153a3', dark: '#778eeb' },
  secondary: { light: '#1e8dfa', dark: '#1e8dfa' },
  info: { light: '#2196f3', dark: '#2196f3' },
  success: { light: '#43a047', dark: '#43a047' },
  error: { light: '#d32f2f', dark: '#d32f2f' },
  warning: { light: '#ff9800', dark: '#ff9800' },
  errorContrast: { light: '#ffffff', dark: '#f7f7f7' },
  helpersPrimary: { light: 'blue', dark: 'blue' },
  second: { light: '#070919', dark: '#070919' },
  topNavBg: { light: '#d5d5d5', dark: '#12181e' },
  tableBorder: { light: '#eee', dark: '#525252' },
  tableNthRow: { light: '#f9f8f8', dark: '#474747' },
  helpersMain: { light: 'rgba(25, 46, 91, .035)', dark: 'rgba(25, 46, 91, .035)' },
  shadow1: { light: 'rgba(0, 0, 0, 0.5)', dark: 'rgba(255, 255, 255, 1)' },
  disabledBtn: { light: 'rgba(255, 255, 255, 0.4)', dark: 'rgba(255, 255, 255, 0.5)' },
  mapLabelColor: { light: '#000', dark: '#fff' },
  mapLabelBorder: { light: '#fff', dark: '#000' },
  mapIconColor: { light: '#242f3e', dark: '#242f3e' },
  secondaryContrast: { light: '#f7f7f7', dark: '#242f3e' },
};

const theme = (colors, themeName) => {
  return {
    colors: {
      text1: colors.text1,
      grey1: colors.grey1,
      grey2: colors.grey2,
      grey3: colors.grey3,
      grey4: colors.grey4,
      grey5: colors.grey5,
      grey6: colors.grey6,
      orange1: colors.orange1,
      paper: colors.paper,
      disabledBtn: colors.disabledBtn,
      border: colors.border,
      active: colors.active,
      errorContrast: colors.errorContrast,
      secondaryContrast: colors.secondaryContrast,
    },
    map: {
      labelColor: colors.mapLabelColor,
      labelBorder: colors.mapLabelBorder,
      iconColor: colors.mapIconColor,
    },
    content: {
      // used in /layout-blueprints/CadLayout.js
      bg: colors.siteBackground,
      color: colors.siteTextColor,
    },
    table: {
      borderColor: colors.tableBorder,
      nthRowBg: colors.tableNthRow,
    },
    card: {
      color: colors.text1,
      bg: colors.paper,
      bg1: colors.grey1,
      bg2: colors.grey2,
      bgHover: colors.grey4,
      hr: colors.grey1,
    },
    tooltip: {
      bg: colors.primary,
      color: colors.text1,
      header: colors.paper,
      border: colors.grey3,
      tableBorder: colors.grey2,
      tableHeaderBg: colors.grey4,
      shadow: colors.shadow1,
    },
    topNav: {
      bg: colors.topNavBg,
    },
    palette: {
      accent1: colors.accent1,
      accent2: colors.accent2,
      type: themeName,
      text: {
        primary: colors.text1,
        secondary: colors.text1,
      },
      primary: {
        main: colors.primary,
      },
      secondary: { main: colors.secondary },
      error: { main: colors.error },
      success: { main: colors.success },
      warning: { main: colors.warning },
      helpers: {
        primary: colors.helpersPrimary,
        main: colors.helpersMain,
      },
      contrastThreshold: 3,
      tonalOffset: 0.1,
    },
    shape: { borderRadius: '0.35rem' },
    overrides: {
      MuiButton: {
        text: {
          paddingLeft: '14px',
          paddingRight: '14px',
        },
        containedSizeSmall: {
          paddingLeft: '14px',
          paddingRight: '14px',
        },
        root: {
          textTransform: 'none',
          fontWeight: 'normal',
        },
      },
      MuiFormLabel: {
        root: {
          color: colors.formPlaceholder,
        },
      },
      MuiTooltip: {
        tooltip: {
          backgroundColor: colors.primary,
          color: colors.paper,
          padding: '8px 16px',
          fontSize: '13px',
        },
        arrow: {
          color: colors.second,
        },
      },
    },
  };
};

export const DayTheme = createMuiTheme(getTheme('light'));
export const NightTheme = createMuiTheme(getTheme('dark'));

function getTheme(themeName) {
  const colors = getColors(themeName);
  return theme(colors, themeName);
}

function getColors(themeMode) {
  const colorScheme = {};
  Object.entries(colorDefinitions).forEach(([name, values]) => {
    colorScheme[name] = values[themeMode];
  });
  return colorScheme;
}
