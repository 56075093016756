import React from 'react';
import { connect } from 'react-redux';
import { Button, Avatar, Box, Menu, List, ListItem, Divider, Badge } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { setUserAuthenticated } from 'reducers/UserReducer';
import { openSettings, showAboutDialog } from 'reducers/DialogsReducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { authorizeExternalApp } from 'reducers/UserReducer';
import { saveUserSetting } from 'reducers/UserSettingsReducer';
import { setMode } from 'reducers/ThemeReducer';
import Checkbox2 from 'components/Checkbox2';

function getSettingsUrl() {
  let url = process.env.REACT_APP_SETTINGS_URL;
  if (!url) return '';
  if (url.substr(-1) !== '/') url += '/';
  url += 'resources';
  return url;
}

const settingsUrl = getSettingsUrl();

const UserBadge = withStyles({
  badge: {
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
})(Badge);

function UserMenu(props) {
  const {
    anchorEl,
    onClose,
    network,
    themeMode,
    setUserAuthenticated,
    user,
    serverConnection,
    setMode,
    enableE911,
  } = props;
  const userName = user?.userData?.user?.Username || '';

  const StyledBadge = withStyles({
    badge: {
      backgroundColor: serverConnection === false ? 'var(--danger)' : 'var(--success)',
      color: serverConnection === false ? 'var(--danger)' : 'var(--success)',
    },
  })(UserBadge);

  const toggleColorMode = () => {
    const newMode = themeMode === 'night' ? 'day' : 'night';
    setMode();
    props.saveUserSetting('themeMode', newMode);
    setMode(newMode);
    onClose();
  };

  const showAboutDialog = () => {
    props.showAboutDialog();
    onClose();
  };

  const handleOpenOptions = () => {
    props.openSettings();
    onClose();
  };

  const handleOpenSettings = () => {
    authorizeExternalApp();
    onClose();
  };

  const handleEnableE911 = (val) => {
    if (enableE911 !== val) props.saveUserSetting('enableE911', val);
  };

  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      getContentAnchorEl={null}
      open={Boolean(anchorEl)}
      anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
      transformOrigin={{ vertical: 'center', horizontal: 'center' }}
      onClose={onClose}
      className="ml-2">
      <div className="dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
        <List className="text-left bg-transparent d-flex align-items-center flex-column pt-0">
          <Box>
            <StyledBadge
              overlap="circle"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              variant="dot">
              <Avatar sizes="44" alt={userName} />
            </StyledBadge>
          </Box>
          <div>
            <div className="font-weight-bold text-center pt-2 line-height-1">{userName}</div>
          </div>
          <Divider className="w-100 mt-2" />
          <ListItem style={{ marginBottom: -16 }}>
            <Checkbox2
              checked={enableE911}
              onChange={(ev, val) => handleEnableE911(val)}
              label="Enable 911 popup"
            />
          </ListItem>
          <Divider className="w-100 mt-2" />
          <ListItem onClick={toggleColorMode} button>
            {themeMode === 'day' ? 'Night mode' : 'Day mode'}
          </ListItem>
          <ListItem onClick={showAboutDialog} button>
            About
          </ListItem>
          <ListItem onClick={handleOpenOptions} button>
            Options
          </ListItem>
          <ListItem
            onClick={handleOpenSettings}
            button
            component="a"
            href={settingsUrl}
            target="_settings"
            disabled={!settingsUrl}>
            Settings
          </ListItem>
          <Divider className="w-100" />
          <ListItem className="d-block rounded-bottom px-3 pt-3 pb-0 text-center">
            <Button
              onClick={() => setUserAuthenticated(false)}
              variant="outlined"
              size="medium"
              color="default">
              <span className="btn-wrapper--icon">
                <FontAwesomeIcon icon={['fas', 'sign-out-alt']} />
              </span>
              <span className="btn-wrapper--label">Logout</span>
            </Button>
          </ListItem>
        </List>
      </div>
    </Menu>
  );
}

const mapStateToProps = (state) => ({
  network: state.offline,
  themeMode: state.userSettings.themeMode,
  serverConnection: state.serverConnection,
  enableE911: state.userSettings.enableE911,
});

export default connect(mapStateToProps, {
  setUserAuthenticated,
  showAboutDialog,
  openSettings,
  saveUserSetting,
  setMode,
})(UserMenu);
