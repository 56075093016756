import store from 'config/configureStore';
import { getSettings, clearSettings } from './ConfigReducer';
import { clearEvents } from './EventsReducer';
import { clearUnits } from './UnitsReducer';
import { updateZones } from './ZonesReducer';
import { notify } from './NotifierReducer';
import { getService } from './service';
import Cookies from 'js-cookie';
import { getLocalSettings, saveLocalSettings, clearAppModeData } from './ConfigReducer';
import { handleError } from './ErrorReducer';
import { checkForNewVersion, updateApp } from './AppInfoReducer';

export const USER_SET_AUTHENTICATED = 'USER/SET_AUTHENTICATED';
export const USER_SET_DATA = 'USER/SET_DATA';
export const USER_SET_USER_UNIT = 'USER/USER_SET_USER_UNIT';
export const USER_SET_USER_UNITS = 'USER/SET_USER_UNITS';
export const USER_CLEAR_DATA = 'USER/USER_CLEAR_DATA';
const PASS_EXPIRATION = 'PASS_EXPIRATION';
const PASS_RESET = 'PASS_RESET';
const PASSWORD_FIELD_SHOULD_RESET = 'PASSWORD_FIELD_SHOULD_RESET';

let logOutSent = false;

export const cookieAuthOn = () => {
  const auth = window.localStorage.getItem('auth');
  document.cookie = 'auth=' + auth + ';path=/';
};

export const cookieAuthOff = () => {
  document.cookie = 'auth=' + ';path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT';
};

export const submitLoginForm = (username, password) => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const user = await client.authenticate({
        strategy: 'local',
        username: username,
        password: password,
        AppName: 'CAD',
      });
      logOutSent = false;
      dispatch({ type: USER_SET_DATA, payload: user });
      dispatch({ type: USER_SET_AUTHENTICATED, payload: true });
      dispatch(getSettings());
      dispatch(updateZones());
    } catch (error) {
      const { code, message } = error;
      switch (code) {
        case 408:
          dispatch(
            notify(
              'It takes too long to connect to the server. Please check your connection and try again.',
              'warning'
            )
          );
          break;
        case 401:
          if (message) {
            dispatch({ type: PASSWORD_FIELD_SHOULD_RESET, payload: true });
            dispatch(notify(message, 'error'));
          } else {
            dispatch(notify('Login failed. Please try again.', 'error'));
          }
          break;
        default:
          if (message) {
            dispatch(notify(message, 'error'));
          } else {
            dispatch(
              notify(`Unknown error ${code}. Please check you connection and try again.`, 'error')
            );
          }
      }
    }
  };
};

export const checkPassExpiration = () => {
  const service = getService('authenticate');
  return async (dispatch) => {
    try {
      const user = await service.get(0);
      if (!user) return;
      const { PassExpirationDays, ptsUserID } = user;
      dispatch({ type: PASS_EXPIRATION, payload: PassExpirationDays });
      const currentTime = new Date().getTime();
      let prevTime = getLocalSettings(`changePassReminder${ptsUserID}`);
      if (!prevTime) prevTime = 0;
      const showReminder =
        (currentTime - prevTime > 12 * 60 * 60 * 1000 && PassExpirationDays < 4) ||
        PassExpirationDays === 0;
      if (showReminder) dispatch(showPassExpiration());
    } catch (err) {
      dispatch(handleError(err));
    }
  };
};

export const showPassExpiration = () => (dispatch) => {
  dispatch({ type: PASS_RESET, payload: true });
};

export const hidePassExpiration = () => (dispatch) => {
  const state = store.store.getState();
  const ptsUserID = state?.user?.userData?.user?.ptsUserID;
  const savedSetting = { [`changePassReminder${ptsUserID}`]: new Date().getTime() };
  saveLocalSettings(savedSetting);
  dispatch({ type: PASS_RESET, payload: false });
};

export const setUserAuthenticated = (state) => {
  const client = store.store.getState().websocket.websocket;
  return (dispatch) => {
    if (client) {
      if (state === false) {
        dispatch(logOut());
      } else {
        dispatch({ type: USER_SET_AUTHENTICATED, payload: true });
      }
    }
  };
};

export const logOut = () => async (dispatch) => {
  if (logOutSent) return;
  logOutSent = true;
  dispatch(clearAppModeData());
  try {
    const state = store.store.getState();
    const { appInfo } = state;
    const client = state.websocket.websocket;
    if (client) {
      client
        .service('authenticate')
        .remove('CAD')
        .catch((e) => {});
      client.logout();
    } else {
      window.localStorage.removeItem('auth');
    }
    dispatch({ type: USER_CLEAR_DATA });
    dispatch(clearSettings());
    dispatch(clearEvents());
    dispatch(clearUnits());
    dispatch({ type: USER_SET_AUTHENTICATED, payload: false });
    // check if new version of app is available and log out if so
    const { registration } = appInfo;
    if (registration) {
      updateApp(registration);
    } else {
      const { buildNo } = appInfo;
      const newVer = await checkForNewVersion();
      if (buildNo && buildNo !== newVer?.build) window.location.reload();
    }
  } catch (err) {
    console.log(err);
  }
};

export const setUserData = (data) => {
  return (dispatch) => {
    dispatch({ type: USER_SET_DATA, payload: data });
  };
};

// ----------------------- units

export const setUserUnits = (units) => {
  return (dispatch) => {
    dispatch({ type: USER_SET_USER_UNITS, payload: units });
  };
};

export const setPasswordFieldShouldReset = (flag = false) => {
  return (dispatch) => {
    dispatch({ type: PASSWORD_FIELD_SHOULD_RESET, payload: flag });
  };
};

export const setUserUnit = (unit) => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    if (unit) {
      dispatch({ type: USER_SET_USER_UNIT, payload: unit });
    } else {
      if (!client) return;
      try {
        const unitID = store.store.getState().user.userUnit.ptsUnitID;
        const unit = await client.service('units').get(unitID);
        dispatch({ type: USER_SET_USER_UNIT, payload: unit[0] });
      } catch (error) {
        console.log('UserReducer/setUserUnit: error: ', error, error.code);
      }
    }
  };
};

export const authorizeExternalApp = () => {
  const isProduction = process.env.NODE_ENV !== 'development';
  const auth = window.localStorage.getItem('auth');
  const domain = window.location.hostname;
  const parentDomain = domain.substr(domain.indexOf('.') + 1);
  Cookies.set('auth', auth, {
    expires: new Date(new Date().getTime() + 2500),
    path: '/',
    domain: parentDomain,
    secure: isProduction,
  });
};

export const setSearchCookie = (data) => {
  const isProduction = process.env.NODE_ENV !== 'development';
  const domain = window.location.hostname;
  const parentDomain = domain.substr(domain.indexOf('.') + 1);
  Cookies.set('search', JSON.stringify(data), {
    expires: new Date(new Date().getTime() + 10000),
    path: '/',
    domain: parentDomain,
    secure: isProduction,
  });
};

export const changePass = (currentPass, newPass) => {
  const service = getService('authenticate');
  return service.patch(0, { currentPass, newPass, AppName: 'CAD' });
};

export const resetPass = (hash, newPass) => {
  const service = getService('pass-reset');
  return service.patch(hash, { newPass, AppName: 'CAD' });
};

export const sendPassResetLink = (email) => {
  const service = getService('pass-reset');
  return service.create({
    action: 'pass-reset',
    email,
    ResetMethod: 'email',
    AppName: 'CAD',
    RedirectTo: process.env.REACT_APP_PUBLIC_URL + '/Reset-password',
  });
};

export const validateResetPassHash = (hash) => {
  const service = getService('pass-reset');
  return service.get(hash);
};

const defaultState = {
  isAuthenticated: false,
  userData: null,
  passExpirationDays: null,
  showPassChange: false,
  passwordFieldShouldReset: false,
};

/* Workstation protection - should be removed in production */
const wrkKey = process.env.REACT_APP_WORSTATION_KEY;
const savedWrkKey = window.atob(window.localStorage.getItem('wrk-ps'));
const wrkKeyMatch = !wrkKey || savedWrkKey === wrkKey;

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case USER_SET_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.payload && wrkKeyMatch,
      };
    case USER_SET_DATA:
      return {
        ...state,
        userData: action.payload,
      };
    case USER_CLEAR_DATA:
      return defaultState;
    case PASS_EXPIRATION:
      return {
        ...state,
        passExpirationDays: action.payload,
      };
    case PASS_RESET:
      return {
        ...state,
        showPassChange: action.payload,
      };
    case PASSWORD_FIELD_SHOULD_RESET:
      return {
        ...state,
        passwordFieldShouldReset: action.payload,
      };
    default:
      break;
  }
  return state;
}
